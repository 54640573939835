/*
jQuery UI Sortable.
 */
.ui-sortable-helper {
  opacity: 0.7; // Reduce opacity during drag.
}

/*
jQuery File Uploader.
https://github.com/blueimp/jQuery-File-Upload
 */
.btn-upload {
  position: relative;
  overflow: hidden;
}

.btn-upload input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  font-size: 200px;
  direction: ltr;
  cursor: pointer;
}

#progress {
  display: none;
  position: fixed;
  top: $app-upload-progress-top;
  left: 0;
  width: 100%;
  z-index: 1040;

  .bar {
    background-color: $app-upload-progress-bg;
    height: $app-upload-progress-height;
    transition: width .3s;
  }
}

/*
jQuery UI Sortable.
 */
.ui-datepicker-row-break {
  clear: both;
  width: 100%;
  font-size: 0;

  &::after {
    content: "";
    display: table;
    border-collapse: collapse;
  }
}

.ui-datepicker {
  display: none;
  background-color: $datepicker-bg;
  padding: $datepicker-spacer;
  color: $datepicker-color;
  border: $datepicker-border;
  min-width: 300px;
}

.ui-datepicker-multi-2 {
  padding: $datepicker-spacer-y 0;

  .ui-datepicker-group {
    float: left;
    width: 50%;
    padding: 0 $datepicker-spacer-x;
  }

  .ui-datepicker-group-first .ui-datepicker-title {
    text-align: right;
  }

  .ui-datepicker-group-last .ui-datepicker-title {
    text-align: left;
  }
}

.ui-datepicker-header {
  position: relative;
  margin: $datepicker-header-margin;
  padding: $datepicker-header-padding;
  background-color: $datepicker-header-bg;
  color: $datepicker-header-color;
}

.ui-datepicker-prev, .ui-datepicker-next {
  position: absolute;
  top: 0;
  padding: $datepicker-spacer;

  span {
    display: block;
    cursor: pointer;
  }
}

.ui-datepicker-prev {
  left: 0;
}

.ui-datepicker-next {
  right: 0;
}

.ui-datepicker-title {
  text-align: center;
  font-weight: bold;
}


.ui-datepicker-calendar {
  width: 100%;
  border-collapse: collapse;
}

.ui-datepicker th {
  padding: $datepicker-link-padding;
  text-align: center;
  font-weight: bold;
  border: 0;
}

.ui-datepicker td {
  padding: $datepicker-td-padding;
  border: $datepicker-td-border;

  &.ui-state-disabled {
    border: 0;
  }
}

.ui-datepicker td span,
.ui-datepicker td a {
  display: block;
  text-align: right;
  text-decoration: none;
}

.ui-datepicker td a {
  padding: $datepicker-link-padding;
  background-color: $datepicker-link-bg;
  color: $datepicker-link-color;

  &.ui-state-hover {
    background-color: $datepicker-link-hover-bg;
    color: $datepicker-link-hover-color;
  }
}

.ui-datepicker-current-day a {
  background-color: $datepicker-link-current-bg;
  color: $datepicker-link-current-color;
}

.ui-datepicker-today a {
  background-color: $datepicker-link-today-bg;
  color: $datepicker-link-today-color !important;
}

/*
jQuery UI Autocomplete
 */
.ui-autocomplete {
  position: absolute;
  top: 0;
  left: 0;
  cursor: default;
  background: $input-bg;
  z-index: 10;
  border: $input-border-width solid $input-border-color;

  @if $enable-rounded {
    border-radius: $input-border-radius;
  }
}

.ui-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
  outline: 0;
}

.ui-menu-item-wrapper {
  cursor: pointer;
  padding: $input-padding-y $input-padding-x;
  font-size: $font-size-base;
  line-height: $input-line-height;
  color: $input-color;

  &.ui-state-active {
    background-color: $gray-300;
  }
}

.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}